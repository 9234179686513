.invest-container {
  display: flex;
  gap: 10px;
  padding: 1rem;
  margin: 0 auto;
  max-width: 1280px;
  margin-top: 40px;
}

/* .invest-left, .invest-right{
  width: 50%;
} */
.invest-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.invest-cards-data {
  background: #fafafa;
  padding: 25px;
  height: 185px;
  width: 330px;
  border-radius: 16px;
}
.cards-no {
  font-size: 37.5px;
  font-weight: 600;
  letter-spacing: -0.52px;
}
.data-wrapper {
  margin-top: 20px;
}
.data-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}
.invest-right-container {
  position: relative;
}
.overlay {
  width: 520px;
  height: 140px;
  position: absolute;
  top: 10%;
  padding: 20px;
}
.overlay p {
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  color: rgba(255, 255, 255, 1);
}
.invest-button {
  position: absolute;
  bottom: 10%;
  left: 4%;
  padding: 17px 21px;
  background: rgba(255, 255, 255, 1);
  border-radius: 32px;
  display: flex;
  gap: 8px;
  font-weight: 500;
  text-decoration: none;
  color: inherit;
  align-items: center;
}

.invest-img {
  height: 380px;
  border-radius: 24px;
  width: 630px;
  object-fit: fill;
}

@media screen and (max-width: 768px) {
  .invest-container {
    flex-direction: column;
  }
  .invest-cards {
    grid-template-columns: repeat(1, 1fr);
  }

  .invest-cards-data,
  .invest-left,
  .invest-right {
    width: 100%;
  }
  .invest-right-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .invest-img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .overlay {
    width: 90%;
    top: 15%;
    padding: 10px;
    text-align: center;
  }

  .overlay p {
    font-size: 20px;
    line-height: 30px;
  }

  .invest-button {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    padding: 12px 16px;
    font-size: 14px;
  }
}
