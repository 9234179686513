.parent {
  margin: 0 auto;
  max-width: 1280px;
  display: flex;
  margin-top: 40px;
  padding: 1rem;
}
.child1 {
  width: 30%;
}
.child2 {
  width: 30%;
}
.child2 img {
  margin-top: 1rem;
}
.child3 {
  width: 40%;
}
.child1-left {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 12px;
}
.child3-points {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  color: #26292e;
}
.child3-points img {
  object-fit: contain;
}
.efficiency-text {
  font-size: 18px;
  font-weight: 600;
}
.text-light {
  font-size: 16px;
  font-weight: 400;
}
.capital-efficiency-text {
  font-weight: 600;
  font-size: 34px;
  padding-bottom: 10px;
}
.sub-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding-bottom: 20px;
}
.points-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media screen and (max-width: 768px) {
  .efficiency-arrow {
    display: none;
  }
  .parent {
    flex-wrap: wrap;
  }

  .child1 {
    width: 100%;
    padding-left: 22px;
  }

  .child3 {
    width: 100%;
    padding: 22px;
  }
  .capital-efficiency-text {
    font-size: 22px;
  }
}
