.articles-container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 1rem;
}

.customer {
  text-align: start;
  font-size: 26.16px;
  font-weight: 600;
}

.card-container {
  padding-bottom: 15px;
  text-decoration: none;
  color: inherit;
}
.card_wrapper {
  display: flex;
  gap: 1rem;
}
.card_wrapper img {
  width: 100%;
}
.card_wrapper .card-container {
  flex: 1;
}

.customer_name span {
  color: #830dbf;
  font-size: 13px;
  font-weight: 600;
  line-height: 25px;
}

.customer_detail {
  margin: 10px 0;
}
.customer_detail p {
  font-size: 20px;
  font-weight: 600;
}
.description {
  font-size: 13px;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .card_wrapper {
    flex-wrap: wrap;
  }
  .card_wrapper .card-container {
    width: 100%;
    flex: auto;
  }
  .card-images {
    width: 85%;
  }
  .customer_detail {
    line-height: 20px;
  }
}
