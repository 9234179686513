.section_qoute {
  color: white;
  font-size: 46.88px;
  font-weight: 600;
}

.section_content {
  color: rgba(204, 204, 204, 1);
  font-size: 16px;
  margin-top: 1.3rem;
  max-width: 430px;
  font-weight: 400;
}
.banner-btn-wrapper {
  display: flex;
  gap: 1rem;
  margin-top: 60px;
}
.banner-img {
  height: 300px;
  width: 240px;
  object-fit: fill;
}
.invest-btn {
  padding: 16.5px 57px;
  background-color: white;
  border: none;
  border-radius: 25px;
  color: #830dbf;
  text-decoration: none;
  font-size: 15px;
}

.hero {
  background-color: #830dbf;
  height: 500px;
  display: grid;
  place-items: center;
}

.hero-container {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 30px 20px;
}
.left-section {
  width: 60%;
  /* padding: 25px; */
}
.right-section {
  width: 40%;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .hero {
    height: 450px;
  }
  .hero-container {
    width: 100%;
    flex-wrap: wrap;
  }
  .hero-container > div {
    width: 100%;
  }
  .banner-img {
    height: 150px;
    width: 150px;
  }
  .section_qoute {
    font-size: 25px;
    padding: 0 15px;
  }
  .section_content {
    padding: 0 15px;
  }
  .banner-btn-wrapper {
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .banner-btn-wrapper button {
    font-size: 14px;
  }
}
@media screen and (max-width: 574px) {
  .hero-container {
    width: 100%;
    flex-wrap: wrap;
  }
  .section_qoute {
    font-size: 22px;
  }
  .section_content {
    font-size: 12px;
    padding: 0 15px;
  }
  .invest-btn {
    padding: 10px 20px;
  }
}
