.header {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  background-color: rgba(131, 13, 191, 1);
}

.header-container {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  width: 100%;
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.company-name {
  font-weight: 500;
  font-size: 32px;
  line-height: 38.73px;
  color: rgba(255, 255, 255, 1);
}

.navigation-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.navigation-list {
  display: flex;
  list-style: none;
}

.navigation-list a {
  text-decoration: none;
  color: inherit;
}

.navigation-item {
  font-size: 14px;
  line-height: 15.36px;
  color: rgba(255, 255, 255, 1);
  margin-right: 25px;
  cursor: pointer;
}

.launch-app-button {
  font-size: 14px;
  line-height: 13px;
  color: #830dbf;
  background-color: rgba(250, 250, 250, 1);
  padding: 10px 25px;
  border-radius: 26px;
  border: none;
  cursor: pointer;
  text-decoration: none;
}

.language-selector {
  display: flex;
  align-items: center;
  padding: 3px 18.06px;
  cursor: pointer;
  gap: 10px;
}

.language-text {
  font-weight: 400;
  font-size: 13.56px;
  line-height: 16.41px;
  color: rgba(255, 255, 255, 1);
}
.language-select {
  border-width: 4px 4px 0px 4px;
  border-style: none;
  border-color: rgba(255, 255, 255, 1);
  outline: none;
  background: transparent;
}

.menu_close_icon {
  display: none;
}

@media screen and (max-width: 768px) {
  .navigation-container {
    display: none;
  }
  .launch-app-button {
    display: none;
  }
  .language-selector {
    display: none;
  }
  .menu_close_icon {
    display: block;
  }
}
