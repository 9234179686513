@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}
