.sidebar {
  height: 100vh;
  width: 300px;
  position: fixed;
  background-color: white;
  top: 0;
  z-index: 100;
  transition: 0.3s right ease-in-out;
}
.sidebar_header {
  padding: 1rem;
}
.sidebar_items {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.sidebar_items li {
  padding: 0.5rem 1rem;
  font-size: 16px;
  list-style: none;
}
.sidebar_items a {
  text-decoration: none;
  color: black;
}
.sidebar_btn {
  border-radius: 26px;
  background-color: rgba(131, 13, 191, 1);
  padding: 10px 25px;
  border: none;
  font-size: 13px;
  font-weight: 700;
  color: white;
}
.sidebar.sidebar_hide {
  right: -600px !important;
}

@media screen and (max-width: 768px) {
  .sidebar.sidebar_show {
    right: 0 !important;
  }
}
