footer {
  height: 340px;
  width: 100%;
  background-color: #830dbf;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer-box {
  height: 90%;
  width: 11%;
}

.first-box,
.last-box {
  width: 15%;
}

.second-box {
  padding: 10px;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
}

.second-box a {
  text-decoration: none;
  color: rgba(255, 255, 255, 1);
  font-size: 15px;
  margin-top: 10px;
}
.footer-box-images {
  display: flex;
  gap: 15px;
}

.copyright-area {
  height: 100px;
  width: 100%;
  background-color: #8b21c3;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.left-copyright-area {
  height: 100%;
  width: 10%;
  display: flex;
  align-items: center;
  color: rgba(204, 204, 204, 1);
}

.right-copyright-area {
  height: 100%;
  width: 70%;
  display: flex;
  align-items: center;
  color: rgba(204, 204, 204, 1);
  font-size: 13px;
}

@media (max-width: 768px) {
  footer {
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
  }

  .footer-box {
    width: 90%;
    margin-bottom: 10;
    border-bottom: 1px solid white;
    padding: 10px 30px;
  }

  .first-box,
  .last-box {
    width: 15%;
  }

  .last-box {
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .copyright-area {
    width: 100%;
    height: 150px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
